import React from "react"

import SEO from "../components/seo"
import LayoutInfo from "../components/layout-info"
import img1 from "./../images/skoler1.jpg"
import img2 from "./../images/skoler2.jpg"
import styles from "./info-til-skoler.module.scss"


class SchoolsPage extends React.Component {

    render() {

        return (
            <LayoutInfo>
                <SEO title="Informasjon til skoler"
                    description="Informasjon om Leirskoledagboken til skoler "
                />

                <div className="row">
                    <div className="col">
                        <h1 className="card-title">Leirskole - Ta vare på leirskolestemningen!</h1>
                        <div>
                            <p>
                                Alle leirskolene vi samarbeider med sender oss sin sesongoversikt før sesongstart. Vi kontakter alle skoler som skal på leirskole så tidlig vi kan før oppholdet med informasjon om hva som skal til for å lage Leirskoledagbok.
                                De skolene som ønsker at det lages bok sender navn på elever og kontaktinformasjon til foresatte slik at vi kan sende ut innloggingskode til hver enkelt.
                                Da vil de som er interessert kunne bestille boken til fritt gjennomsyn. (Skolen forplikter seg ikke til å innhente informasjon om hvem som vil bestille bok.) Da koster bøkene kr 419,- inkl porto pr stk.
                            </p>
                            <p>
                                For å ivareta GDPR-regler benytter vi sikker navneliste-opplasting der listene blir kryptert og lagret forsvarlig. Vennligst benytt denne lenken for å laste opp klasseliste:  <a href="/namelist/">leirskolestemning.no/namelist/</a>.
                            </p>
                            <p>
                                Det er også mulig å bestille komplett klassesett av boka. (Fellesbestilling). Noen velger å bruke klassekassa for bøker til alle, da gjør vi en tilpasset avtale om dette med gode rabatter. Ta kontakt med oss for å få et godt tilbud!
                            </p>

                            <img className={` ${styles.infoPicture} rounded float-left mr-3 mb-2`}  src={img1} alt="Illustrasjonsfoto" />

                            <p>
                                Når det er bestemt at bok skal lages tar vi oss av videre avtaler med leirskolen. Dette innebærer at leirskolen får beskjed om at det skal lages leirskoledagbok slik at kameraer gjøres klart for dere.
                                Leirskolene har kameraer som lånes ut og kan benyttes av så vel elever som lærere. Men smart at medfølgende lærer passer på at også de elevene som holder seg litt «i utkanten» også blir representert.
                                På noen av leirskolene går leirskolelærerne også med eget kamera.
                            </p>
                            <p>
                                Etter oppholdet sørger leirskolen for å sende bilder og tekst til oss, og vi har grafikere som setter opp bøkene for hver uke. Hver uke og hver bok er unik. Dersom dere har bilder tatt
                                med mobil eller eget kamera, setter vi stor pris på om dere vil sende oss disse. Jo flere bilder, desto bedre og tykkere bøker! Egne bilder kan lastes opp innen 3 dager etter oppholdet.
                                            Eller etter nærmere avtale. Lenke for innsending av bilder:  <a target="_blank " href="https://send-bilder.leirskolestemning.no/">send-bilder.leirskolestemning.no</a>
                            </p>
                            <img className={` ${styles.infoPicture} rounded float-right  ml-3 mb-2`} src={img2} alt="Illustrasjonsfoto" />
                            
                            <p>
                                Vi har valgt å ikke legge ut bøkene til elektronisk gjennomsyn. Til gjengjeld har vi lang, fri returrett på 30 dager. Dette har vi gjort bl.a for å ivareta personvernet.
                                Vi har heller ingen navnsetting i våre bøker. Dersom det er elever som ikke skal avbildes av ulike årsaker tar vi gjerne en korrektur-runde med dere før trykking slik at evt. bilder kan tas ut.
                            </p>
                            <p>
                                Hvis skolen din skal på leirskole, og du ikke har fått en henvendelse enda, ikke nøl med å ta kontakt med oss for en uforpliktende prat <a href="mailto:ingar@leirskoledagboken.no">ingar@leirskoledagboken.no</a>
                            </p>
                            
                        </div>
                    </div>
                </div>
            </LayoutInfo >
        )
    }

}
export default SchoolsPage
